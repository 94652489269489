import { hot } from 'react-hot-loader';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { RevolusendProvider } from './context';
import { DatePickerProvider } from './components/datePicketProvider';



let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#7199de',
      main: '#3d6bac',
      dark: '#00417c',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#d1ba6c',
      main: '#9e8a40',
      dark: '#6d5d12',
      contrastText: '#000000',
    },
  },
});
theme = responsiveFontSizes(theme);

const App = () => (
  <React.Fragment>
    <DatePickerProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RevolusendProvider>
            <Router />
          </RevolusendProvider>
        </ThemeProvider>
    </DatePickerProvider >
  </React.Fragment>
)

export default hot(module)(App);
