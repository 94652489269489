import React from 'react';
import { Grid, Typography, CircularProgress, Container, Link } from '@material-ui/core';
import { useContext, useApi } from '../context';
import { useHistory, Redirect } from 'react-router-dom';
import utils from '../services/utils.service';
import { QRCode } from 'react-qr-svg';
import { useTranslation } from 'react-i18next';

export const PaymentCheck = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const revolupayOrderId = context.data.revolupayOrderId
  const revolupayOrderReference = context.data.revolupayOrderReference;

  const { t } = useTranslation('payment');

  if (!revolupayOrderId || !revolupayOrderReference) {
    return (
      <Redirect to='/' />
    )
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      utils.runAsync(async () => {
        const hasOrderBeenPaid = await api.hasRevoluPAYOrderBeenPaid(revolupayOrderId);
        if (hasOrderBeenPaid) {
          history.push('/payments/ok/'+context.data.transactionReference);
        }
      });
    }, 2500);
    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <Container>
      <Grid container justifyContent='center' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h3' align='center'>
            {t('Payment')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom align='center'>
            {t('PAYMENT_DESCRIPTION')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <CircularProgress />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <QRCode value={revolupayOrderReference} style={{ width: 250 }} />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Link
            href={`https://www.revolupay.com/pnp?action=complete_order&order_reference=${revolupayOrderReference}`}
            target='_blank'
            rel='noopener'>
            {t('PAY_IN_APP')}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('PAGE_WILL_REFRESH')}
          </Typography>
        </Grid>
      </Grid>
    </Container >
  );
}