import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import koImg from '../assets/img/error.svg'
import { useApi, useContext } from '../context';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '../components/loadingButton';

export const PaymentAftKo = () => {
  const { t } = useTranslation('payment');
  const { transactionReference } = useParams<{ transactionReference: string }>();
  const api = useApi();
  const context = useContext();

  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    context.reset();
  }, []);

  const retry = async () => {
    setIsLoading(true);
    try {

      const response = await api.retryCecaAft(transactionReference);
      if (response.payment_url) {
        window.location.href = response.payment_url;
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Grid container justifyContent='center' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('PAYMENT_KO_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('PAYMENT_KO_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <img src={koImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('PAYMENT_KO_DESCRIPTION')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <LoadingButton
            onClick={retry}
            loading={isLoading}
            disabled={isLoading}
            size='large'
            color='primary'
            variant='contained'>
            Retry
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}