import React from 'react';
import { Grid, Typography, makeStyles, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useApi, useContext } from '../context';
import { Country } from '../services/types/country.type';
import utils from '../services/utils.service';
import { Currency } from '../services/types/currency.type';
import { TransactionType } from '../services/types/transactionType.type';
import { useHistory, useLocation } from 'react-router-dom';
import { TransferWizard, TransferWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { CircleFlag } from 'react-circle-flags';
import { ExchangeMarkup } from '../services/types/exchangeMarkup.type';
import { TransferDisclaimer } from './disclaimer';
import { RevolutransferAlert } from './revolutransferAlert';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const Destination = () => {
  const api = useApi();
  const { t } = useTranslation(['transferDestination', 'deliveryMethod', 'countries']);
  const context = useContext();
  const history = useHistory();
  const location = useLocation();

  const { queryCountryId, queryCurrencyId, queryTransactionTypeId } = (() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      return {
        queryCountryId: query.get('country_id') ? parseInt(query.get('country_id')!!, 10) : null,
        queryCurrencyId: query.get('currency_id') ? parseInt(query.get('currency_id')!!, 10) : null,
        queryTransactionTypeId: query.get('deliverymethod_id') ? parseInt(query.get('deliverymethod_id')!!, 10) : null
      };
    } else {
      return {
        queryCountryId: undefined,
        queryCurrencyId: undefined,
        queryTransactionTypeId: undefined
      }
    }
  })();
  const [showRevolutransferAlert, setShowRevolutransferAlert] = React.useState(false);

  const [exchangeMarkups, setExchangeMarkups] = React.useState<ExchangeMarkup[] | null>(null);

  const [mode, setMode] = React.useState('SEND');
  const [amount, setAmount] = React.useState(100);
  const [exchangeRate, setExchangeRate] = React.useState(0);

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<null | Country>(null);

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [sourceCurrencies, setSourceCurrencies] = React.useState<Currency[]>([]);
  const [selectedSourceCurrency, selectSourceCurrency] = React.useState<null | Currency>(null);
  const [selectedCurrency, selectCurrency] = React.useState<null | Currency>(null);

  const [transactionTypes, setTransactionTypes] = React.useState<TransactionType[]>([]);
  const [selectedTransactionType, selectTransactionType] = React.useState<null | TransactionType>(null);

  const [loadingCountries, setLoadingCountries] = React.useState(false);
  const [loadingCurrencies, setLoadingCurrencies] = React.useState(false);
  const [loadingSourceCurrencies, setLoadingSourceCurrencies] = React.useState(false);
  const [loadingDeliveryMethods, setLoadingDeliveryMethods] = React.useState(false);

  const getMarkup = (): number => {
    if (exchangeMarkups && exchangeMarkups.length > 0) {
      return exchangeMarkups[0].markup;
    }
    return 0;
  }

  const isLoading = () => loadingCountries || loadingCurrencies || loadingDeliveryMethods || loadingSourceCurrencies;

  const verify = () => {
    if (amount <= 0) {
      return;
    }
    if (mode === 'SEND' && amount > 3000) {
      setShowRevolutransferAlert(true);
      return;
    } else if ((amount / exchangeRate) > 3000) {
      setShowRevolutransferAlert(true);
      return;
    }

    context.setData({
      productSelectionParams: {
        country: selectedCountry!,
        sourceCurrency: selectedSourceCurrency!,
        currency: selectedCurrency!,
        transactionType: selectedTransactionType!,
        exchangeRate,
        mode: mode === 'SEND' ? 'SEND' : 'RECEIVE',
        amount
      }
    });
    history.push('/transfer/options')
  }




  React.useEffect(() => {
    setLoadingCountries(true);
    setLoadingSourceCurrencies(true);
    utils.runAsync(async () => {
      const theCountries = await api.getProductCountries();
      setCountries(theCountries);
      if (context.data.productSelectionParams?.country) {
        selectCountry(context.data.productSelectionParams.country);
      } else if (queryCountryId) {
        const foundCountry = theCountries.find(c => c.id === queryCountryId);
        if (foundCountry) {
          selectCountry(foundCountry);
        }
      }
    }, () => {
      setLoadingCountries(false);
    });
    utils.runAsync(async () => {
      setExchangeMarkups(await api.getExchangeRateMarkups());
      setSourceCurrencies(await api.getSourceCurrencies());
    }, () => {
      setLoadingSourceCurrencies(false);
    })
  }, []);

  React.useEffect(() => {
    setLoadingDeliveryMethods(true);
    utils.runAsync(async () => {
      if (selectedCountry) {
        selectTransactionType(null);
        selectCurrency(null);
        const deliveryMethods = await api.getTransactionTypes(selectedCountry.id)
        setTransactionTypes(deliveryMethods);
        if (
          context.data.productSelectionParams?.transactionType &&
          deliveryMethods.find(dm => dm.id === context.data.productSelectionParams?.transactionType.id)
        ) {
          selectTransactionType(context.data.productSelectionParams.transactionType);
          return;
        } else if (queryTransactionTypeId) {
          const foundTransactionType = deliveryMethods.find(c => c.id === queryTransactionTypeId);
          if (foundTransactionType) {
            selectTransactionType(foundTransactionType);
          }
        } else if (deliveryMethods.length === 1) {
          selectTransactionType(deliveryMethods[0]);
        }
      } else {
        setTransactionTypes([]);
        selectTransactionType(null);
      }
    }, () => {
      setLoadingDeliveryMethods(false);
    });
  }, [selectedCountry]);


  React.useEffect(() => {
    setLoadingCurrencies(true);
    utils.runAsync(async () => {
      if (selectedTransactionType && selectedCountry) {
        selectCurrency(null);
        const currenciesFiltered = await api.getCurrencies(selectedCountry.id, selectedTransactionType.id)
        setCurrencies(currenciesFiltered);
        if (context.data.productSelectionParams?.currency && currenciesFiltered.find(c => c.id === context.data.productSelectionParams?.currency.id)) {
          selectCurrency(context.data.productSelectionParams.currency);
        } else if (queryCurrencyId) {
          const foundCurrency = currenciesFiltered.find(c => c.id === queryCurrencyId);
          if (foundCurrency) {
            selectCurrency(foundCurrency);
          }
        } else if (currenciesFiltered.length === 1) {
          selectCurrency(currenciesFiltered[0]);
        }
      } else {
        setCurrencies([]);
        selectCurrency(null);
      }
    }, () => {
      setLoadingCurrencies(false);
    });
  }, [selectedTransactionType]);

  React.useEffect(() => {
    if (selectedSourceCurrency === null) {
      if (context.data.productSelectionParams?.sourceCurrency) {
        selectSourceCurrency(context.data.productSelectionParams!.sourceCurrency);
      } else if (sourceCurrencies.length > 0) {
        const eurCurrency = sourceCurrencies.find(c => c.iso === 'EUR')
        selectSourceCurrency(eurCurrency ? eurCurrency : sourceCurrencies[0]);
      }
    }
  }, [selectedCurrency])

  React.useEffect(() => {
    if (selectedTransactionType && selectedCurrency && selectedSourceCurrency) {
      if (selectedSourceCurrency!.id === selectedCurrency!.id) {
        setExchangeRate(1.000)
      } else {
        const markup = getMarkup();
        const rate = selectedCurrency.rate / selectedSourceCurrency.rate;
        const rateWithMarkup = rate * (1 + markup / 100);
        setExchangeRate(1 / rateWithMarkup);
      }
    }
  }, [selectedSourceCurrency, selectedCurrency]);


  const classes = useStyles();

  return (
    <TransferWizard
      canGoNext={selectedTransactionType !== null}
      loading={false}
      next={verify}
      step={TransferWizardStep.DESTINATION}
    >
      <RevolutransferAlert
        open={showRevolutransferAlert}
        onClose={() => {
          setShowRevolutransferAlert(false);
        }}
      />
      <TransferDisclaimer />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {t('INSTRUCTIONS')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!loadingCountries &&
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              value={selectedCountry}
              options={countries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => t('countries:' + option.iso3)}
              renderOption={(option) => (
                <React.Fragment>
                  <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                  <span style={{ marginLeft: 10 }}>{t('countries:' + option.iso3)} ({option.iso2})</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose a country')}
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedCountry ? (
                      <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    ) : undefined
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          }
        </Grid>
        {selectedCountry && !loadingDeliveryMethods &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectTransactionType(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedTransactionType}
              options={transactionTypes}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => t(`deliveryMethod:${option.code}`)}
              renderOption={(option) => `${t(`deliveryMethod:${option.code}`)} ${t(`deliveryMethod:${option.code}_DESCRIPTION`)}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose a delivery method')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedCountry && selectedTransactionType && !loadingCurrencies &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              value={selectedCurrency}
              getOptionSelected={(option, value) => option.id === value.id}
              options={currencies}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.iso}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose the receive currency')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedCountry && selectedCurrency && selectedTransactionType &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectSourceCurrency(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedSourceCurrency}
              options={sourceCurrencies}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.iso}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose the source currency')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }



        {selectedCountry && selectedCurrency && selectedTransactionType && selectedSourceCurrency &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setMode(value!!);
              }}
              getOptionSelected={(option, value) => option === value}
              value={mode}
              options={['SEND', 'RECEIVE']}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              disableClearable
              getOptionLabel={(option) => t(option)}
              renderOption={(option) => t(option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('MODE')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedCountry && selectedCurrency && selectedTransactionType && selectedSourceCurrency &&
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('AMOUNT')}
                  variant='outlined'
                  value={utils.round(amount).toString()}
                  onChange={(event) => {
                    let amount = Number(event.target.value)
                    if (!isNaN(amount) && selectedCurrency) {
                      setAmount(amount);
                    }
                  }}
                  type='number'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>{mode === 'SEND' ? selectedSourceCurrency.iso : selectedCurrency!.iso}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2' component='span'>
                  {t('EXCHANGE_RATE')}
                </Typography>
                <Typography variant='subtitle1' component='span'>
                  {`${utils.currencyFormat(1)} ${selectedSourceCurrency.iso} = ${utils.currencyFormat(exchangeRate, 4)} ${selectedCurrency.iso}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        }
        {isLoading() &&
          <Grid item xs={12}>
            <Grid container justify='center'>
              <CircularProgress />
            </Grid>
          </Grid>
        }
      </Grid>
    </TransferWizard>
  );
}


