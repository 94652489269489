import React from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  Container,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  CardHeader,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  CardActionArea,
  CardMedia
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Typed from 'react-typed';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import { Country } from '../services/types/country.type';
import { Currency } from '../services/types/currency.type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import remittanceImg from '../assets/img/remittance.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import revolupayImg from '../assets/img/revolupay.png'
import revoluvipImg from '../assets/img/revoluvip.jpg'
import revoluchargeImg from '../assets/img/revolucharge.png'
import revolutilityImg from '../assets/img/revolutility.png'
import TimerIcon from '@material-ui/icons/Timer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LockIcon from '@material-ui/icons/Lock';
import { CircleFlag } from 'react-circle-flags';
import revolupayLogo from '../assets/img/revolupay-logo.png'
import playstoreLogo from '../assets/img/playstore.svg'
import appStoreLogo from '../assets/img/appstore.svg'
import { PromotionListItem } from '../services/types/promotionListItem.type';
import promo1Img from '../assets/img/promos/promo1.jpg'
import promo2Img from '../assets/img/promos/promo2.jpg'
import promo3Img from '../assets/img/promos/promo3.jpg'
import promo4Img from '../assets/img/promos/promo4.jpg'
import promo5Img from '../assets/img/promos/promo5.jpg'
import promo6Img from '../assets/img/promos/promo6.png'
import promo7Img from '../assets/img/promos/promo7.png'
import promo8Img from '../assets/img/promos/promo8.png'
import bdeLogo from '../assets/img/bde.svg'
import moment from 'moment';
import { PromotionType } from '../services/types/promotion.type';
import { Promo } from './promo';
import { TransactionType } from '../services/types/transactionType.type';

const promoImages = utils.shuffleArray([promo1Img, promo2Img, promo3Img, promo4Img, promo5Img, promo6Img, promo7Img, promo8Img]);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    pickDestinationWrapper: {
      maxWidth: 650,
      margin: '0 auto',
      flexGrow: 1
    },
    pickDestinationHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center'
    },
    img: {
      maxWidth: '100%',
    },
    featureBox: {
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(4, 0, 4, 0),
      marginTop: theme.spacing(6)
    },
    revolupayLogo: {
      width: 400,
      maxWidth: '100%',
    },
    bdeLogo: {
      width: 250,
      maxWidth: '100%',
    }
  })
)


const DestinationPickerBox = () => {
  const classes = useStyles();
  const { t } = useTranslation('home');
  const api = useApi();
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<null | Country>(null);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [sourceCurrencies, setSourceCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<null | Currency>(null);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const context = useContext();
  const [loadingCurrencies, setLoadingCurrencies] = React.useState(false);
  const [loadingDeliveryMethods, setLoadingDeliveryMethods] = React.useState(false);
  const [transactionTypes, setTransactionTypes] = React.useState<TransactionType[]>([]);
  const [selectedTransactionType, selectTransactionType] = React.useState<null | TransactionType>(null);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getProductCountries());
    }, () => {
      setLoading(false);
    });
    utils.runAsync(async () => {
      setSourceCurrencies(await api.getSourceCurrencies());
    });
  }, []);

  React.useEffect(() => {
    setLoadingDeliveryMethods(true);
    utils.runAsync(async () => {
      if (selectedCountry) {
        selectTransactionType(null);
        selectCurrency(null);
        const deliveryMethods = await api.getTransactionTypes(selectedCountry.id)
        setTransactionTypes(deliveryMethods);
        if (
          context.data.productSelectionParams?.transactionType &&
          deliveryMethods.find(dm => dm.id === context.data.productSelectionParams?.transactionType.id)
        ) {
          selectTransactionType(context.data.productSelectionParams.transactionType);
          return;
        } else if (deliveryMethods.length === 1) {
          selectTransactionType(deliveryMethods[0]);
        }
      } else {
        setTransactionTypes([]);
        selectTransactionType(null);
      }
    }, () => {
      setLoadingDeliveryMethods(false);
    });
  }, [selectedCountry]);

  React.useEffect(() => {
    setLoadingCurrencies(true);
    utils.runAsync(async () => {
      if (selectedTransactionType && selectedCountry) {
        selectCurrency(null);
        const currenciesFiltered = await api.getCurrencies(selectedCountry.id, selectedTransactionType.id)
        setCurrencies(currenciesFiltered);
        if (context.data.productSelectionParams?.currency && currenciesFiltered.find(c => c.id === context.data.productSelectionParams?.currency.id)) {
          selectCurrency(context.data.productSelectionParams.currency);
        } else if (currenciesFiltered.length === 1) {
          selectCurrency(currenciesFiltered[0]);
        }
      } else {
        setCurrencies([]);
        selectCurrency(null);
      }
    }, () => {
      setLoadingCurrencies(false);
    });
  }, [selectedTransactionType]);

  return (
    <Card className={classes.pickDestinationWrapper}>
      <CardHeader
        title={t('Where do you want to send money to?')}
        className={classes.pickDestinationHeader}
      />
      <CardContent>
        <Grid container spacing={2}>
          {(countries.length > 0 || !loading) &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectCountry(value);
                }}
                value={selectedCountry}
                options={countries}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <React.Fragment>
                    <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    <span style={{ marginLeft: 10 }}>{option.name} ({option.iso2})</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose a country')}
                    variant='outlined'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedCountry ? (
                        <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                      ) : undefined
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>

          }
        {selectedCountry && !loadingDeliveryMethods &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectTransactionType(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedTransactionType}
              options={transactionTypes}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => t(`deliveryMethod:${option.code}`)}
              renderOption={(option) => `${t(`deliveryMethod:${option.code}`)} ${t(`deliveryMethod:${option.code}_DESCRIPTION`)}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose a delivery method')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedCountry && selectedTransactionType && !loadingCurrencies &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectCurrency(value);
              }}
              value={selectedCurrency}
              getOptionSelected={(option, value) => option.id === value.id}
              options={currencies}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.iso}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose the receive currency')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
          {selectedCountry && selectedCurrency && selectedTransactionType && !loading &&
            <>
              <Grid item xs={12}>
                {sourceCurrencies.map(c => (
                  <Typography variant='h4' component='p' align='center' key={c.iso}>
                    {`${utils.currencyFormat(1)} ${c.iso} = ${utils.currencyFormat(1 / selectedCurrency.rate * c.rate, 4)} ${selectedCurrency.iso}`}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={12} container justifyContent='flex-end'>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='large'
                  onClick={() => {
                    const query = new URLSearchParams();
                    query.append('country_id', selectedCountry.id.toString());
                    query.append('currency_id', selectedCurrency.id.toString());
                    query.append('deliverymethod_id', selectedTransactionType.id.toString());
                    history.push('/transfer/destination?' + query.toString());
                  }}
                >{t('CONTINUE')}</Button>
              </Grid>
            </>
          }
          {loading &&
            <Grid container justifyContent='center'>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export const Home = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation('home');
  const api = useApi();

  const [countries, setCountries] = React.useState<Country[]>([]);
  const context = useContext();

  React.useEffect(() => {
    context.reset();
    utils.runAsync(async () => {
      setCountries(await api.getProductCountries());
    }, () => {
    });
  }, []);


  const Header = () => {
    const getRandomSubarray = (arr: any[], size: number) => {
      var shuffled = arr.slice(0), i = arr.length, temp, index;
      while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(0, size);
    }
    const defaultCountries = [t('Mexico'), t('Colombia'), t('Kenya'), t('Ghana'), t('Honduras'), t('Argentina'), t('Bolivia')];
    const apiCountries = getRandomSubarray(countries.map(c => c.name), 15);
    let countriesList;
    if (countries.length > 0) {
      countriesList = [...apiCountries, t('and many more')];
    } else {
      countriesList = [...defaultCountries, t('and many more')];
    }
    return (
      <Container>
        <Promo />
        <Grid container spacing={4} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignContent='center'>
              <Grid item xs={12}>
                <Typography variant='h2' component='h1' align='center'>
                  {t('Send money online to')}
                </Typography>
                <Typography variant='h3' component='h2' align='center' color={'secondary'}>
                  <Typed
                    strings={countriesList}
                    typeSpeed={80}
                    backSpeed={30}
                    loop={true}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' component='h3' align='center'>
                  {t('SUBTITLE')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <img src={remittanceImg} className={classes.img} />
          </Grid>

          <Grid item xs={12}>
            <DestinationPickerBox />
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Box maxWidth={650}>
              <Typography align='center'>
                <Trans t={t} i18nKey='REVOLUTRANSFER_MESSAGE'>
                  For sending larger amounts please use our <a href='https://www.revolutransfer.com'>RevoluTRANSFER</a> platform.
                </Trans>
              </Typography>
            </Box>
          </Grid>
          <Grid container item spacing={4} xs={12} alignItems='center'>
            <Grid item xs={12} md={6} container spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    <img className={classes.revolupayLogo} src={revolupayLogo} />
                  </Grid>
                  <Grid item container justifyContent='center' xs={12}>
                    <Box maxWidth={600} alignContent='center' alignSelf='center'>
                      <Typography variant='subtitle1' align='center'>
                        {t('REVOLUPAY_DESCRIPTION')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container justifyContent={'flex-end'}>
                      <Grid item>
                        <Link href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' rel='noopener' target='_blank'>
                          <img style={{ width: 150, maxWidth: '100%' }} src={appStoreLogo} />
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent={'flex-start'}>
                      <Grid item>
                        <Link href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' rel='noopener' target='_blank'>
                          <img style={{ width: 150, maxWidth: '100%' }} src={playstoreLogo} />
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent='center'>
                  <Grid item container justifyContent='center' xs={12}>
                    <Box maxWidth={600} alignContent='center' alignSelf='center'>
                      <Trans t={t} i18nKey='BDE_DESCRIPTION'>
                        <Typography variant='subtitle1' align='center'>
                          {'REVOLUPAY EP S.L. is a Payment Entity authorized and supervised by the '}
                          <Link href='https://www.bde.es/bde/es/' target='_blank' rel='noopener'>{'Central Bank Of Spain'}</Link>
                          {' and listed in the '}
                          <Link href='https://www.bde.es/bde/es/secciones/servicios/Particulares_y_e/Registros_de_Ent/' target='_blank' rel='noopener'>{'Institutions register'}</Link>
                          {' with code '}
                          <Link href='https://www.revolusend.com/RegistroOficialDeEntidades-6900.pdf' target='_blank' rel='noopener'>{'6900'}</Link>
                          {'.'}
                        </Typography>
                      </Trans>
                    </Box>
                  </Grid>
                  <Grid item>
                    <img className={classes.bdeLogo} src={bdeLogo} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </Grid>
      </Container>
    )
  }

  const Features = () => (
    <Box className={classes.featureBox}>
      <Container>
        <Grid container alignItems='flex-start' spacing={4}>
          <Grid item xs={12} sm={4}>
            <Grid container alignItems='center' direction='column'>
              <Grid item>
                <TimerIcon color='secondary' style={{ fontSize: 80 }} />
              </Grid>
              <Grid item>
                <Typography variant={'h6'} component={'span'} align='center'>
                  {t('Fast')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align='center'>
                  {t('FAST_DETAILS')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container alignItems='center' direction='column'>
              <Grid item>
                <MonetizationOnIcon color='secondary' style={{ fontSize: 80 }} />
              </Grid>
              <Grid item>
                <Typography variant={'h6'} component={'span'} align='center'>
                  {t('Best value')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align='center'>
                  {t('BEST_VALUE_DETAILS')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container alignItems='center' direction='column'>
              <Grid item>
                <LockIcon color='secondary' style={{ fontSize: 80 }} />
              </Grid>
              <Grid item>
                <Typography variant={'h6'} component={'span'} align='center'>
                  {t('Safe and secure')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align='center'>
                  {t('SAFE_AND_SECURE_DETAILS')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )

  const Faq = () => (
    <Container style={{ marginTop: theme.spacing(6) }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h4'} align='center'>
            {t('EXPLANATION_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('Do I need to create an account in order to send transfers?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='SIGNUP_EXPLANATION' >
                <Typography>
                  {'0'}
                  <Link href='https://www.revolupay.es' target='_blank' rel='noopener'>{'1'}</Link>
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('How does it work?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='FUNCTION_EXPLANATION' >
                <Typography>
                  {'0'}
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('How do I send a transfer?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='SENDING_EXPLANATION' >
                <Typography component='span'>
                  In order to send a transfer just follow these steps:
                  <ul>
                    <li>Make sure that you have a RevoluPAY account. If you don't head to <a href='https://www.revolupay.es' target='_blank' rel='noopener'>RevoluPAY</a> and sign up for one.</li>
                    <li>Head over to <a href='/transfer'>New transfer</a> and select your destination country and currency and delivery method.</li>
                    <li>User your RevoluPAY account if you haven't already.</li>
                    <li>Depending on the delivery method you will have to pick some delivery specific attributes (the destination bank or the pickup location for instance).</li>
                    <li>Enter the personal data of the beneficiary or select one from your contact list.</li>
                    <li>Enter a concept or reason for the transfer.</li>
                    <li>With some delivery methods you will need to enter the account number of the beneficiary.</li>
                    <li>Finally you will pay for the transfer with your revolupay account, by scanning the QR code or following the in APP pay link that is generated.</li>
                    <li>Once the order is payed you and the beneficiary will receive a confirmation e-mail and we will process the transfer. Once the order is processed you will receive a status update notification. You can also check the status of the transfer under the <a href='/account'>My Account</a> section</li>
                  </ul>
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('How much does it cost?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='COST_EXPLANATION' >
                <Typography>
                  The cost of a transfer will depend on different factors such as the destination country, the currency and the amount you want to send. During the checkout process you will see the exact that the beneficiary will receive and how much it will cost you.
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('What data do I need of the recipient?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='RECEIVER_EXPLANATION' >
                <Typography>
                  You will need information about his id card or passport, the full name, address, phone number and e-mail address.
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('How is money delivered to the recipient?')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='DELIVERY_METHOD_EXPLANATION' >
                <Typography component='span'>
                  That depends on the delivery method. We support different delivery methods and the availabality depends on the destination country and currency. Some examples are:
                  <ul>
                    <li>For instant bank transfers: The beneficiary will get his money as soon as you receive the transfer status update email. This should not take longer than a few hours.</li>
                    <li>For mobile wallet transfers: The beneficiary will get his money as soon as you receive the transfer status update email. This should not take longer than a few hours.</li>
                    <li>For cash pickup transfers: The beneficiary needs to pick up the money at the selected location which he receives in his confirmation email. He will need the confirmation number that was sent to his e-mail address and a valid id card. The confirmation number will normally be send out after 24 hours, but in some cases it can take up to 72 hours as the transfer is validated.</li>
                    <li>For ATM transfers: The beneficiary will receive an SMS with a pin code which can then be used at the ATM to withdraw the money. This SMS should be send out after a few hours, but in some cases it can take up to 72 hours as the transfer is validated.</li>
                  </ul>
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  )

  const Promos = () => {
    const api = useApi();
    const history = useHistory();
    const { i18n, t } = useTranslation('promotions');
    moment.locale(i18n.language);
    const [promotions, setPromotions] = React.useState<PromotionListItem[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
      utils.runAsync(async () => {
        const allpromos = await api.getActivePromotions();
        setPromotions(allpromos.slice(0, Math.min(allpromos.length, 8)));
      }, () => {
        setLoading(false);
      });
    }, []);

    const getPromoTitle = (p: PromotionListItem) => {
      if (p.promotion.type === PromotionType.REGULAR) {
        return t('REGULAR_PROMO_TITLE')
      } else {
        return t('WELCOME_PROMO_TITLE')
      }
    }

    const getPromoDecription = (p: PromotionListItem) => {
      const attributes = {
        start: moment(p.promotion.start).format('lll'),
        end: moment(p.promotion.end).format('lll'),
        usages: p.promotion.usages,
        country: p.country ? p.country.name : t('ALL_COUNTRIES'),
        amount: `${utils.currencyFormat(p.promotion.amount)} €UR`
      };
      if (p.promotion.type === PromotionType.REGULAR) {
        return t(
          'REGULAR_PROMO_DESCRIPTION',
          attributes
        )
      } else {
        return t(
          'WELCOME_PROMO_DESCRIPTION',
          attributes
        )
      }
    }

    return (
      <Container style={{ marginTop: theme.spacing(6) }}>
        <Grid container spacing={4} justifyContent='center'>
          {loading &&
            <CircularProgress />
          }
          {promotions.map(p => (
            <Grid item xs={12} sm={6} md={3} key={p.promotion.id}>
              <Card>
                <CardActionArea onClick={() => {
                  if (p.country) {
                    const query = new URLSearchParams();
                    query.append('country_id', p.country.id.toString());
                    history.push('/transfer/destination?' + query.toString());
                  } else {
                    history.push('/transfer/destination')
                  }
                }}>
                  <CardMedia
                    component='img'
                    src={promoImages[promotions.indexOf(p)]}
                    title='Promotion'
                  />
                  {p.country &&
                    <div style={{ position: 'absolute', top: 15, right: 15 }}>
                      <CircleFlag countryCode={p.country.iso2.toLowerCase()} height={50} cdnUrl="/" />
                    </div>
                  }
                  <CardContent>
                    <Typography style={{ textDecoration: 'none !important' }} gutterBottom variant='h6'>
                      {getPromoTitle(p)}
                    </Typography>
                    <Typography variant='subtitle2' color='textSecondary' component="p">
                      {getPromoDecription(p)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }


  const Services = () => (
    <Container style={{ marginTop: theme.spacing(6) }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h4'} align='center'>
            {t('Check out our highlighted services')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revolupay.es' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revolupayImg}
                  title='RevoluPAY'
                />
                <CardContent>
                  <Typography style={{ textDecoration: 'none !important' }} gutterBottom variant='h6'>
                    {t('RevoluPAY')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUPAY_DETAILS')} </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revoluvip.club/' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revoluvipImg}
                  title='RevoluVIP'
                />
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    {t('RevoluVIP')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUVIP_DETAILS')} </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revolucharge.com' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revoluchargeImg}
                  title='RevoluCHARGE'
                />
                <CardContent>
                  <Typography style={{ textDecoration: 'none !important' }} gutterBottom variant='h6'>
                    {t('RevoluCHARGE')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUCHARGE_DETAILS')} </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revolutility.com' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revolutilityImg}
                  title='RevolUTILITY'
                />
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    {t('RevolUTILITY')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUTILITY_DETAILS')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>

      </Grid>
    </Container>
  )

  return (
    <>
      <Header />
      <Promos />
      <Features />
      <Faq />
      <Services />
    </>
  )
}