import { Config } from '../config';
import { Country } from './types/country.type';
import { Currency } from './types/currency.type';
import { TransactionType } from './types/transactionType.type';
import { Agent } from './types/agent.type';
import { Location } from './types/location.type';
import { AccountType } from './types/acocuntType.type';
import { Beneficiary } from './types/beneficiary.type';
import { Auth } from './auth.service';
import { UserProfile } from './types/userProfile.type';
import { IdCardType } from './types/idCardType.enum';
import { Contact } from './types/contact.type';
import { Transaction } from './types/transaction.type';
import { Region } from './types/region.type';
import { LocationListItem } from './types/locationListItem.type'
import { PromotionListItem } from './types/promotionListItem.type';
import { Promotion, PromotionVerificationError } from './types/promotion.type';
import { Quotation } from './types/quotation.type';
import { ExchangeMarkup } from './types/exchangeMarkup.type';
import { CubaZones } from './types/cubaZones.type';

export class Api {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async getProductCountries(): Promise<Country[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/products/countries',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).countries;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }

  async getCountries(): Promise<Country[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/countries',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).countries;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }


  async getCurrencies(countryId: number, transactionTypeId: number): Promise<Currency[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/transaction-types/${transactionTypeId}/currencies`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).currencies;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }


  async getSourceCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/currencies/source`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).currencies;
    } else {
      throw new Error('Got wrong status code while fetching source currencies');
    }
  }


  async getTransactionTypes(countryId: number): Promise<TransactionType[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/transaction-types`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).transaction_types;
    } else {
      throw new Error('Got wrong status code while fetching transaction types');
    }
  }


  async getAgents(
    countryId: number,
    currencyId: number,
    transactionTypeCode: string
  ): Promise<Agent[]> {
    const urlParams = new URLSearchParams();
    urlParams.append('country_id', countryId.toString());
    urlParams.append('currency_id', currencyId.toString());
    urlParams.append('transaction_type_code', transactionTypeCode);
    const response = await fetch(
      this.config.API_BASE_URL + '/agents?' + urlParams.toString(),
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).agents
    } else {
      throw new Error('Got wrong status code while fetching agents');
    }
  }

  async getAccountTypesForAgent({
    agentId, currencyId, countryId, transactionTypeId
  }: {
    agentId: number;
    currencyId: number;
    countryId: number;
    transactionTypeId: number
  }): Promise<AccountType[]> {
    const query = new URLSearchParams();
    query.append('currency_id', currencyId.toString(10));
    query.append('country_id', countryId.toString(10));
    query.append('transaction_type_id', transactionTypeId.toString(10));
    const response = await fetch(
      this.config.API_BASE_URL + `/agents/${agentId}/account-types?${query.toString()}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).account_types
    } else {
      throw new Error('Got wrong status code while fetching account types');
    }
  }

  async getLocationsForAgentAndCountry(agentId: number, countryId: number): Promise<Location[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/agents/${agentId}/locations?country_id=${countryId}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).locations
    } else {
      throw new Error('Got wrong status code while fetching agent locations');
    }
  }

  async retryCecaAft(transactionReference: string): Promise<{ payment_url: string }> {
    return {
      payment_url: this.config.API_BASE_URL + '/ceca-processing/aft?transaction_reference=' + transactionReference
    }
  }

  async getOctUrl(transactionReference: string): Promise<{ url: string }> {
    return {
      url: this.config.API_BASE_URL + '/ceca-processing/oct?transaction_reference=' + transactionReference
    }
  }

  async getTransactionInfo(reference: string): Promise<{
    provider_code: string,
    transaction_type_code: string,
    transaction_status: string
  }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/transactions/' + reference,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    )
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Got wrong status code whille getting transaction info');
    }
  }

  async checkout(
    paymentMethod: 'REVOLUPAY' | 'PAYLANDS' | 'AFT_CECA',
    concept: string,
    quotationId: number,
    receiver: Beneficiary,
    locationId?: number,
    senderRegionCode?: string,
    promotionId?: number
  ): Promise<{
    revolupay_order_id?: number;
    revolupay_order_reference?: string;
    transaction_id: number;
    transaction_reference: string;
    paylands_payment_url?: string;
    payment_url?: string;
  }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/checkout',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY
        },
        body: JSON.stringify({
          payment_method: paymentMethod,
          concept,
          quotation_id:quotationId,
          receiver,
          location_id: locationId,
          sender_region_code: senderRegionCode,
          promotion_id: promotionId
        })
      }
    )
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Got wrong status code while checking out');
    }
  }

  async hasRevoluPAYOrderBeenPaid(
    revolupayOrderId: number
  ): Promise<boolean> {
    const response = await fetch(
      this.config.API_BASE_URL + '/revolupay_orders/' + revolupayOrderId + '/payment',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return true
    } else if (response.status === 404) {
      return false;
    } else {
      throw new Error('Got wrong status code while checking if payment has been paid');
    }
  }

  async getMyProfile(): Promise<UserProfile> {
    const response = await fetch(
      this.config.API_BASE_URL + '/me',
      {
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Got wrong status code while getting profile info.');
    }
  }

  async addContact(contact: {
    first_name: string;
    last_name: string;
    id_card_country_id: number;
    id_card_type: IdCardType;
    id_card_number: string;
    id_card_expiry_date?: string;
    id_card_issue_date?: string;
    residence_country_id?: number;
    postal_code?: string;
    region?: string;
    address?: string;
    city?: string;
    phone: string;
    email: string;
  }): Promise<number> {
    const response = await fetch(
      this.config.API_BASE_URL + '/contacts',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY
        },
        body: JSON.stringify(contact)
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while adding contact.');
    }
    return (await response.json()).id;
  }


  async getContacts(): Promise<Contact[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/contacts',
      {
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).contacts.map((d: any) => d.contact);
    } else {
      throw new Error('Got wrong status code while getting contacts.');
    }
  }

  async deleteContact(id: number) {
    const response = await fetch(
      this.config.API_BASE_URL + `/contacts/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong status code while deleting contacts.');
    }
  }

  async getTransactions(limit: number, offset: number): Promise<{ transactions: Transaction[], count: number }> {
    const response = await fetch(
      this.config.API_BASE_URL + `/transactions?limit=${limit}&offset=${offset}&order=desc`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        },
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while getting transactions.');
    }
    return await response.json();
  }

  async getRegions(countryId: number): Promise<Region[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/regions`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while fetching country regions.');
    }
    return (await response.json()).regions;
  }

  async getLocations(
    request: {
      countryId: number;
      currencyId: number;
      transactionTypeId: number;
      amount: number;
      lat: number;
      long: number;
      radius?: number;
      agentId?: number
    }): Promise<LocationListItem[]> {
    const query = new URLSearchParams();
    query.append('country_id', request.countryId.toString(10));
    query.append('currency_id', request.currencyId.toString(10));
    query.append('transaction_type_id', request.transactionTypeId.toString(10));
    query.append('amount', request.amount.toString(10));
    query.append('lat', request.lat.toString(10));
    query.append('long', request.long.toString(10));
    if (!request.radius) {
      query.append('radius', '100');
    } else {
      query.append('radius', request.radius.toString(10));
    }
    if (request.agentId) {
      query.append('agent_id', request.agentId.toString(10));
    }
    const response = await fetch(
      this.config.API_BASE_URL + '/locations?' + query.toString(),
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).locations;
    } else {
      throw new Error('Could not fetch locations');
    }
  }

  async getActivePromotions(): Promise<PromotionListItem[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/promotions',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return (await response.json()).promotions;
    } else {
      throw new Error('Could not fetch promotions');
    }
  }

  async getBestPromotion(productId: number, sourceSurenncyId: number): Promise<Promotion | null> {
    const response = await fetch(
      this.config.API_BASE_URL + `/checkout/promotion?product_id=${productId}&source_currency_id=${sourceSurenncyId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        }
      }
    );
    if (response.ok) {
      return response.json();
    } else if (response.status === 404) {
      return null;
    }
    throw new Error('Could not fetch promotion');
  }

  async getVoucherPromotion(voucher: string, productId: number, sourceCurrencyId: number): Promise<{ promotion?: Promotion, error?: PromotionVerificationError }> {
    const response = await fetch(
      this.config.API_BASE_URL + `/voucher?voucher=${voucher}&product_id=${productId}&source_currency_id=${sourceCurrencyId}`,
      {
        method: 'GET',

        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY
        },
      }
    );
    if (response.ok) {
      return { promotion: (await response.json()), error: undefined };
    } else if (response.status === 404) {
      return { promotion: undefined, error: (await response.json()).error };
    }
    throw new Error('Could not get voucher promotion');
  }

  async getExchangeRateMarkups(): Promise<ExchangeMarkup[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/currencies/exchange_markups',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get exchange markups.')
    }
    return (await response.json()).exchange_markups;
  }

  
  async getQuotation(
    countryId: number,
    currencyId: number,
    sourceCurrencyId: number,
    transactionTypeCode: string,
    amount: number,
    mode: 'SEND' | 'RECEIVE',
    agentId?: number
  ): Promise<Quotation> {

    const response = await fetch(
      this.config.API_BASE_URL + '/quotation',
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          amount,
          mode,
          agent_id: agentId,
          transaction_type_code: transactionTypeCode,
          currency_id: currencyId,
          country_id: countryId,
          source_currency_id: sourceCurrencyId
        })
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Got wrong status code while generating quotation');
    }
  }

  async getCubaZones(): Promise<CubaZones> {
    const response = await fetch(
      this.config.API_BASE_URL + '/countries/cuba/zones',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get cuba zones.')
    }
    return response.json()
  }

}