import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    makeStyles,
    createStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import newsImg from '../assets/img/visa_direct_preferred_partner.png';

const promoId = 'visa-direct';

const useStyles = makeStyles(() =>
    createStyles({
        img: {
            display: 'block',
            width: '100%',
            margin: '0 auto'
        }
    })
);

export const Promo = () => {
    const classes = useStyles();
    const { t } = useTranslation('home');
    const wasPromoDisplayed = localStorage.getItem('promo-' + promoId);
    const [open, setOpen] = React.useState(wasPromoDisplayed === null);
    const onClose = () => {
        localStorage.setItem('promo-' + promoId, moment().toISOString());
        setOpen(false);
    }
    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth
            onClose={onClose}
            aria-labelledby='promo-dialog'
        >
            <DialogContent>
                <img src={newsImg} className={classes.img} />
                <Typography style={{ marginTop: '10px' }} variant='h6' >
                    {t('PROMO_TITLE')}
                </Typography>
                <Typography style={{ marginTop: '12px' }} variant='body1'>
                    {t('PROMO_MESSAGE')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary' autoFocus>
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}
