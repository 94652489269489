import React from 'react';
import { Paper, Grid, Container, Box, Typography, TextField, makeStyles, Theme, createStyles, Link, FormControlLabel, Checkbox } from '@material-ui/core';
import revolupayLogo from '../assets/img/revolupay-logo.png'
import { useTranslation, Trans } from 'react-i18next';
import { useAuth, useContext, useApi } from '../context';
import utils from '../services/utils.service'
import { LoadingButton } from './loadingButton';
import { Country } from '../services/types/country.type';
import { Autocomplete } from '@material-ui/lab';
import { AlertDialog } from './alertDialog';
import { LoginConditions } from './loginConditionsModal';
import { CircleFlag } from 'react-circle-flags';
import playstoreLogo from '../assets/img/playstore.svg'
import appStoreLogo from '../assets/img/appstore.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  })
);

type Credentials = {
  data: {
    prefix: string;
    phone: string;
    pin: string;
  };
  errors: {
    prefix: boolean;
    phone: boolean;
    pin: boolean;
  }
}

export const Login = ({ children, paper = true }: { children: any, paper?: boolean }) => {
  const { t } = useTranslation('login');
  const api = useApi();
  const context = useContext();
  const auth = useAuth();

  const classes = useStyles();

  const [showConditions, setShowConditions] = React.useState(false);
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const [keepSignedIn, setKeepSignedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<Country | null>(null);
  const [credentials, setCredentials] = React.useReducer(
    (state: Credentials, action: any): Credentials => {
      if (!action.data) action.data = state.data;
      if (!action.errors) action.errors = state.errors
      return {
        data: {
          ...state.data,
          ...action.data
        },
        errors: {
          ...state.errors,
          ...action.errors
        }
      }
    },
    {
      data: { prefix: '', phone: '', pin: '' }, errors: { prefix: false, phone: false, pin: false }
    }
  );

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCountries(await api.getCountries());
    }, () => {
    });
  }, []);

  React.useEffect(() => {
    let prefix = ''
    if (selectedCountry) {
      prefix = '+' + selectedCountry.prefix;
    }
    setCredentials({ data: { prefix }, errors: { prefix: false } });
  }, [selectedCountry]);

  const login = () => {
    const errors = {
      prefix: credentials.data.prefix.length === 0,
      phone: credentials.data.phone.length === 0,
      pin: credentials.data.pin.length === 0
    };
    if (Object.values(errors).includes(true)) {
      setCredentials({ errors });
      return;
    }
    setLoading(true);
    utils.runAsync(async () => {
      const response = await auth.login(credentials.data.prefix + credentials.data.phone, credentials.data.pin, keepSignedIn);
      if (!response.success) {
        if (!response.valid_credentials) {
          setLoginError(t('WRONG_CREDENTIALS'));
        } else {
          setLoginError(t('NO_KYC'));
        }
      }
    }, () => {
      setLoading(false);
    })
  }

  const toggleKeepSignedIn = () => {
    setKeepSignedIn(!keepSignedIn);
  }

  const handleFormUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ data: { [e.target.name]: e.target.value }, errors: { [e.target.name]: false } });
  }

  if (context.data.isSignedIn) {
    return children;
  }
  const getLoginBox = () => (
    <>
      <AlertDialog
        open={loginError !== undefined}
        title={t('COULD_NOT_LOGIN')}
        message={loginError ? loginError : ''}
        onClose={() => setLoginError(undefined)}
      />
      <LoginConditions
        open={showConditions}
        onClose={() => setShowConditions(false)}
      />
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item>
                <img src={revolupayLogo} style={{ height: 40, maxWidth: '100%' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item>
                <Trans t={t} i18nKey='PLEASE_LOGIN'>
                  <Typography variant='h5' align='center'>
                    Log in required
                  </Typography>
                </Trans>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item>
                <Trans t={t} i18nKey='LOGIN_INSTRUCTIONS'>
                  <Typography variant='body1' align='justify'>
                    Use your RevoluPAY credentials to sign in. If you don't already have an account head over to
                    <Link href='https:///www.revolupay.es' target='_blank' rel='noopener'> www.revolupay.es </Link>
                    for further instructions about registration.
                  </Typography>
                </Trans>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              onChange={(event, value) => {
                selectCountry(value);
              }}
              value={selectedCountry}
              options={countries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => `+${option.prefix} - ${option.name}`}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => (
                <React.Fragment>
                  <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                  <span style={{ marginLeft: 10 }}>{`+${option.prefix}`} - {option.name}</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Prefix')}
                  variant='outlined'
                  error={credentials.errors.prefix}
                  helperText={credentials.errors.prefix ? t('REQUIRED') : ''}
                  fullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedCountry ? (
                      <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    ) : undefined
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              label={t('Phone number')}
              error={credentials.errors.phone}
              helperText={credentials.errors.phone ? t('REQUIRED') : ''}
              variant='outlined'
              name={'phone'}
              fullWidth={true}
              onChange={handleFormUpdate}
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('PIN')}
              variant='outlined'
              fullWidth={true}
              error={credentials.errors.pin}
              helperText={credentials.errors.pin ? t('REQUIRED') : ''}
              onChange={handleFormUpdate}
              name={'pin'}
              type='password'
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepSignedIn}
                  onChange={toggleKeepSignedIn}
                  color='primary'
                />
              }
              label={t('KEEP_ME_SIGNED_IN')}
            />
          </Grid>
          <Grid item xs={12}>
            <Trans t={t} i18nKey='TERMS+CONDITIONS'>
              <Typography variant='body2' align='center'>
                By loggin in you accept the <Link onClick={() => setShowConditions(true)}>terms & conditions</Link> of this service.
            </Typography>
            </Trans>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Link href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' rel='noopener' target='_blank'>
                      <img style={{ width: 150, maxWidth:'100%'}} src={appStoreLogo} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent='flex-start'>
                  <Grid item>
                    <Link href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' rel='noopener' target='_blank'>
                      <img style={{ width: 150, maxWidth:'100%'}} src={playstoreLogo} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <LoadingButton variant='outlined' size='large' color='secondary' onClick={login} disabled={loading} loading={loading}>
                  {t('Login')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Container >
      <Grid container justifyContent='center' alignContent='center' alignItems='center'>
        <Grid item xs={12} md={8}>
          {paper &&
            <Paper>
              {getLoginBox()}
            </Paper>
          }
          {!paper &&
            getLoginBox()
          }
        </Grid>
      </Grid>
    </Container >
  )


}