import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageIcon from '@material-ui/icons/Translate';
import Button from '@material-ui/core/Button';
import { MenuItem, Menu, useTheme, Grid, useMediaQuery, Typography, Link, List, ListItem, ListItemText, IconButton, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper, Container } from '@material-ui/core';
import logo from '../assets/img/logo.png'
import revolupayLogo from '../assets/img/revolupay-logo-light.png'
import playstoreLogo from '../assets/img/playstore.svg'
import appStoreLogo from '../assets/img/appstore.svg'
import { useTranslation, Trans } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Zendesk, {ZendeskAPI} from 'react-zendesk';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 80,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    footerContainer: {
      backgroundColor: theme.palette.primary.main,
    },
    footerWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    footerText: {
      color: theme.palette.primary.contrastText
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
);


const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'en' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  const getCurrentLanguage = () => {
    if (i18n.language.includes('es')) {
      return t('SPANISH');
    } else {
      return t('ENGLISH');
    }
  }

  return (
    <>
      <Button aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        <LanguageIcon />
        {getCurrentLanguage()}
        <ExpandMoreIcon fontSize='small' />
      </Button>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>{t('ENGLISH')}</MenuItem>
        <MenuItem onClick={() => setLanguage('es')}>{t('SPANISH')}</MenuItem>
      </Menu>
    </>
  );
};

export const Layout = ({ children }: { children: any }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('layout');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [cookiesAccepted, setCookiesAccepted] = React.useState<string | null>(localStorage.getItem('cookies_accepted'));

  const handleScroll = () => {
    if (window.scrollY > 150) {
      acceptCookies();
      window.removeEventListener('scroll', handleScroll);
    }
  }

  const getLanguageCode = () => {
    if (i18n.language.includes('es')) {
      return 'es';
    } else {
      return 'en-us';
    }
  }

  React.useEffect(() => {
    if (cookiesAccepted) {
      localStorage.setItem('cookies_accepted', cookiesAccepted);
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

  }, [cookiesAccepted]);


  const acceptCookies = () => {
    setCookiesAccepted(new Date().toISOString());
  }

  React.useEffect(()=>{
    setTimeout(()=>{
      ZendeskAPI('webWidget', 'setLocale', i18n.language.includes('es')?'es':'en')
    },1500);
  },[i18n.language]);

  return (
    <div className={classes.root}>
      <Zendesk defer zendeskKey={'1a190b0a-67d5-4f0a-aa9a-881b7efc8aa2'} />
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />

      </MetaTags>

      <AppBar position='static' color='transparent' variant='outlined'>
        <Container className={classes.toolBarContainer}>
          <Toolbar>
            <Grid container alignItems='center'>
              <Grid item xs={12} sm={4} >
                <Grid container justifyContent={isXs ? 'center' : 'flex-start'}>
                  <Grid item>
                    <Link component={RouterLink} to='/'>
                      <img className={classes.logo} src={logo} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} >
                <Grid container justifyContent={isXs ? 'center' : 'flex-end'}>
                  <Grid item>
                    <Button component={RouterLink} to='/transfer' color='inherit'>{t('NEW TRANSFER')}</Button>
                  </Grid>
                  <Grid item>
                    <Button component={RouterLink} to='/account' color='inherit'>{t('MY ACCOUNT')}</Button>
                  </Grid>
                  <Grid item>
                    <LanguageButton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {!cookiesAccepted &&
        <Container>
          <Box padding={2}>
            <Alert severity="info">
              <Grid container alignContent='center' justifyContent='center' alignItems='center' spacing={2}>
                <Grid item sm={10} xs={12}>
                  <Trans t={t} i18nKey='COOKIES'>
                    <Typography align='justify'>
                      RevoluSEND uses cookies to guarantee users the employment of its site features, offering a better purchasing experience and by continuing to browse the site you agree to our use of cookies.
                    </Typography>
                    <Link component={RouterLink} to='/cookies'>
                      <Typography align='justify'>
                        Check out our detailed cookies policy.
                      </Typography>
                    </Link>
                  </Trans>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <Button variant='contained' color='primary' onClick={acceptCookies}>{t('Accept')}</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        </Container>
      }
      <div className={classes.container}>
        {children}
      </div>
      <Paper className={classes.footerContainer} variant='outlined' square={true}>
        <Container className={classes.footerWrapper}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Grid container justifyContent='center' alignContent='center' alignItems='center' spacing={1}>
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Grid item>
                      <img src={revolupayLogo} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Trans t={t} i18nKey='REVOLUPAY_INFO'>
                    <Typography variant='body2' align='center' className={classes.footerText}>
                      {'RevoluSEND is a division of '}
                      <Link
                        className={classes.footerText}
                        href='https://www.revolupay.es'
                        target='_blank'
                        rel='noopener'>
                        {'REVOLUPAY EP S.L'}
                      </Link>
                      {' Payment Service Entity authorized and supervised by the Central Bank of Spain and registered in the Institutions Register with code 6900. REVOLUPAY EP S.L. is a Spanish company wholly owned by'}
                      <Link
                        className={classes.footerText}
                        href='https://www.revolugroup.com'
                        rel='noopener'
                        target='_blank'
                      >
                        {' REVOLUGROUP CANADA INC.'}
                      </Link>
                      {' a publicly traded company on the '}
                      <Link
                        className={classes.footerText}
                        href='https://money.tmx.com/en/quote/REVO'
                        target='_blank'
                        rel='noopener'>
                        {'Toronto Stock Exchange - REVO'}
                      </Link>
                    </Typography>
                  </Trans>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container justifyContent='flex-end'>
                        <Grid item>
                          <Link href='https://itunes.apple.com/us/app/revolupay/id1437640699?mt=8&ign-mpt=uo%3D4' rel='noopener' target='_blank'>
                            <img style={{ width: 150, maxWidth: '100%' }} src={appStoreLogo} />
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justifyContent='flex-start'>
                        <Grid item>
                          <Link href='https://play.google.com/store/apps/details?id=com.revolupayclient.vsla.revolupayconsumer' rel='noopener' target='_blank'>
                            <img style={{ width: 150, maxWidth: '100%' }} src={playstoreLogo} />
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item direction='column' md={2} xs={4} container justifyContent='flex-start' alignItems='center' alignContent='center'>
              <Grid item>
                <Typography variant='h6' className={classes.footerText} align='center'>{t('LEGAL')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem component={RouterLink} to='/about' className={classes.footerText}>
                    <ListItemText primary={t('About us')} />
                  </ListItem>
                  <ListItem component={RouterLink} to='/terms' className={classes.footerText}>
                    <ListItemText primary={t('User Agrement')} />
                  </ListItem>
                  <ListItem component={RouterLink} to='/privacy' className={classes.footerText}>
                    <ListItemText primary={t('Privacy Policy')} />
                  </ListItem>
                  <ListItem component={RouterLink} to='/cookies' className={classes.footerText}>
                    <ListItemText primary={t('Cookies Policy')} />
                  </ListItem>
                  <ListItem component={RouterLink} to='/credits' className={classes.footerText}>
                    <ListItemText primary={t('Credits')} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid item direction='column' md={2} xs={4} container justifyContent='flex-start' alignItems='center' alignContent='center'>
              <Grid item>
                <Typography variant='h6' className={classes.footerText} align='center'>{t('SERVICES')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem component='a' href='https://www.revolupay.es' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevoluPAY')} />
                  </ListItem>
                  <ListItem component='a' href='https://www.revoluvip.club/' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevoluVIP')} />
                  </ListItem>
                  <ListItem component='a' href='https://www.revolucharge.com' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevoluCHARGE')} />
                  </ListItem>
                  <ListItem component='a' href='https://www.revolutility.com' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevolUTILITY')} />
                  </ListItem>
                  <ListItem component='a' href='https://www.revoluegame.com' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevoluEGAME')} />
                  </ListItem>
                  <ListItem component='a' href='https://www.revolufin.com/' rel='noopener' target='_blank' className={classes.footerText}>
                    <ListItemText primary={t('RevoluFIN')} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid item direction='column' md={2} xs={4} container justifyContent='flex-start' alignItems='center' alignContent='center'>
              <Grid item>
                <Typography variant='h6' className={classes.footerText} align='center'>{t('SUPPORT')}</Typography>
              </Grid>
              <Grid item>
                <List dense>
                  <ListItem component='a' href={`https://revolusend.zendesk.com/hc/${getLanguageCode()}`} className={classes.footerText}>
                    <ListItemText primary={t('Help Center')} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com/pages/category/Finance/RevoluPay-928692330663160/' aria-label='facebook'>
                  <FacebookIcon fontSize={'small'} className={classes.footerText} />
                </IconButton>
                <IconButton component='a' rel='noopener' target='_blank' href='https://ve.linkedin.com/company/revolupay' aria-label='linkedin'>
                  <LinkedInIcon fontSize={'small'} className={classes.footerText} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  )
};

